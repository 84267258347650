"use strict";
var _a, _b, _c, _d, _e, _f;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatButtons = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var ChatEnvironment_1 = require("../ChatEnvironment");
var ChatMethod_1 = require("../ChatMethod");
var Language_1 = require("../Language");
var Property_1 = require("../Property");
var AutoTrigger_1 = require("./AutoTrigger");
var sendAnalyticEvents_1 = require("./sendAnalyticEvents");
var NUMBERS = (_a = {},
    _a[ChatEnvironment_1.ChatEnvironment.Production] = (_b = {},
        _b[ChatMethod_1.ChatMethod.WhatsApp] = '12515777924',
        _b[ChatMethod_1.ChatMethod.SMS] = '97639',
        _b[ChatMethod_1.ChatMethod.Instagram] = 'askrenai',
        _b),
    _a[ChatEnvironment_1.ChatEnvironment.Preview] = (_c = {},
        _c[ChatMethod_1.ChatMethod.WhatsApp] = '14178553008',
        _c),
    _a);
var Messages = {
    atProperty: (_d = {},
        _d[Language_1.Language.English] = function (property) { return "Hello, RENAI. I am at the Renaissance ".concat(property, "."); },
        _d[Language_1.Language.French] = function (property) { return "Bonjour, RENAI. Je suis \u00E0 Renaissance ".concat(property, "."); },
        _d),
    noProperty: (_e = {},
        _e[Language_1.Language.English] = 'Hello, RENAI.',
        _e[Language_1.Language.French] = 'Bonjour, RENAI .',
        _e),
    howToCommunicate: (_f = {},
        _f[Language_1.Language.English] = 'How would you like to communicate with RENAI?',
        _f[Language_1.Language.French] = 'Comment voulez-vous communiquer avec RENAI ?',
        _f),
};
var ChatButtons = function (_a) {
    var chatEnvironment = _a.chatEnvironment, chatMethods = _a.chatMethods, currentProperty = _a.currentProperty, lang = _a.lang;
    var hasCurrentProperty = currentProperty !== undefined;
    var numbers = NUMBERS[chatEnvironment];
    var message = hasCurrentProperty
        ? Messages.atProperty[lang]((0, Property_1.shortName)(currentProperty))
        : Messages.noProperty[lang];
    var encodedMessage = encodeURIComponent(message);
    var whatsAppUrl = "https://wa.me/".concat(numbers[ChatMethod_1.ChatMethod.WhatsApp], "?text=").concat(encodedMessage);
    var smsUrl = numbers[ChatMethod_1.ChatMethod.SMS] && "sms:".concat(numbers[ChatMethod_1.ChatMethod.SMS], "?&body=").concat(encodedMessage);
    var instagramUrl = numbers[ChatMethod_1.ChatMethod.Instagram] && "https://ig.me/m/".concat(numbers[ChatMethod_1.ChatMethod.Instagram]);
    if (chatMethods.length === 1 && chatMethods[0]) {
        var chatMethod = chatMethods[0];
        switch (chatMethod) {
            case ChatMethod_1.ChatMethod.SMS:
                if (smsUrl) {
                    return (0, jsx_runtime_1.jsx)(AutoTrigger_1.AutoTrigger, { chatMethod: chatMethod, currentProperty: currentProperty, url: smsUrl });
                }
                else {
                    return (0, jsx_runtime_1.jsx)("p", { children: "No SMS link available" });
                }
            case ChatMethod_1.ChatMethod.Instagram:
                if (instagramUrl) {
                    return (0, jsx_runtime_1.jsx)(AutoTrigger_1.AutoTrigger, { chatMethod: chatMethod, currentProperty: currentProperty, url: instagramUrl });
                }
                else {
                    return (0, jsx_runtime_1.jsx)("p", { children: "No Instagram link available" });
                }
            case ChatMethod_1.ChatMethod.WhatsApp:
                return (0, jsx_runtime_1.jsx)(AutoTrigger_1.AutoTrigger, { chatMethod: chatMethod, currentProperty: currentProperty, url: whatsAppUrl });
        }
    }
    else {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: Messages.howToCommunicate[lang] }), (0, jsx_runtime_1.jsxs)("div", { className: 'buttons are-large is-centered', children: [(0, jsx_runtime_1.jsxs)("a", { className: "button is-outlined is-rounded is-success ".concat(hasCurrentProperty ? '' : 'is-disabled'), href: whatsAppUrl, "aria-disabled": !hasCurrentProperty, onClick: function () {
                                (0, sendAnalyticEvents_1.sendAnalyticEvents)(ChatMethod_1.ChatMethod.WhatsApp, currentProperty);
                            }, children: [(0, jsx_runtime_1.jsx)("span", { className: 'icon', children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faWhatsapp }) }), (0, jsx_runtime_1.jsx)("span", { children: "WhatsApp" })] }), instagramUrl && ((0, jsx_runtime_1.jsxs)("a", { className: "button is-outlined is-rounded is-success ".concat(hasCurrentProperty ? '' : 'is-disabled'), href: instagramUrl, "aria-disabled": !hasCurrentProperty, onClick: function () {
                                (0, sendAnalyticEvents_1.sendAnalyticEvents)(ChatMethod_1.ChatMethod.Instagram, currentProperty);
                            }, children: [(0, jsx_runtime_1.jsx)("span", { className: 'icon', children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faInstagram }) }), (0, jsx_runtime_1.jsx)("span", { children: "Instagram" })] })), smsUrl && ((0, jsx_runtime_1.jsx)("a", { className: "button is-outlined is-rounded is-link ".concat(hasCurrentProperty ? '' : 'is-disabled'), "aria-disabled": !hasCurrentProperty, href: smsUrl, onClick: function () {
                                (0, sendAnalyticEvents_1.sendAnalyticEvents)(ChatMethod_1.ChatMethod.SMS, currentProperty);
                            }, children: (0, jsx_runtime_1.jsx)("span", { children: "SMS" }) }))] })] }));
    }
};
exports.ChatButtons = ChatButtons;
